import React, { useState } from "react";
import AddNysc from "./AddNysc";

function Nysc({ nyscData, refetchNysc, refetchStatus }) {

  const [openEditNyscModal, setOpenEditNyscModal] = useState(false);
  const [addNyscModal, setAddNyscModal] = useState(false);
   
  return (
    <div>
      <div className="font-semibold text-md text:darkBlue flex md:justify-between items-center gap-x-6">
        National Youth Service Corps
        <div
          className="cursor-pointer bi bi-plus-circle border border-armyGreen p-2 rounded-lg text-sm text-armyGreen font-normal"
          onClick={() => setAddNyscModal(true)}
        >
          &nbsp; Add details
          {/* <img src={AddIcon} alt="add-icon" /> */}
        </div>
      </div>
      {addNyscModal && (
        <AddNysc
          closeModal={setAddNyscModal}
          refetchNysc={refetchNysc}
          refetchStatus={refetchStatus}
        />
      )}
      {nyscData?.data === null && (
        <div className="mt-5 text-center text-sm font-medium text-lightGrey">
          Please update your Nysc details
        </div>
      )}

      {
        <>
          <div className="my-2">
            <span className="text-sm">
              { nyscData?.data?.status==='completed'? 'Certificate Number' : 'Call-up Number'}
            </span> :{" "}
            <span className="font-medium text-sm">
              {" "}
              { 
                nyscData?.data?.status==='completed' ?
                 nyscData?.data?.certificate_number
                : nyscData?.data?.call_up_number
              }
            </span>
          </div>
          <div>
            <span className="text-sm">Pop-Date</span> :{" "}
            <span className="font-medium text-sm">
              {`${new Date(nyscData?.data?.pop_date).toLocaleDateString()}`}
            </span>
          </div>
        </>
      }
    </div>
  );
}

export default Nysc;

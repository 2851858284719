import { AxiosInstance } from "axios";
import { createApiClient } from "./apiClient";
import { 
    getCoursesType, createEducationType,
    getInstitutionsType,
    getUserBiodataType, updateUserBiodataType, userId,
    updateEducationType,
    deleteEducationType,
    createUserExperinceType,
    updateUserExperinceType,
    deleteUserExperinceType,
    addNyscDataType,
  } 
  from "./types/profile.types";

const client: AxiosInstance = createApiClient()

export const profile = {

 getUserBiodatav2: ({ userID } : getUserBiodataType ) =>
  client.get(`/v2/candidate/${userID}/bio-data`).then(({ data }) => data),

 getStatesv2: () => client.get(`/v2/states`).then(({ data }) => data),
 
 getDegreesv2: () => client.get(`/v2/degrees`).then(({ data }) => data),

  getClassOfDegreesv2: () =>
    client.get(`/v2/class-of-degrees`).then(({ data }) => data),

  updateUserBiodatav2: ({payload, userId} : {payload: updateUserBiodataType, userId: userId} ) =>
    client
      .patch(`v2/candidate/${userId}/bio-data`, { ...payload })
      .then(({ data }) => data),

 getProfileStatusv2: ( userId: userId) =>
   client.get(`/v2/candidate/${userId}/status`).then(({ data }) => data),

 getUserEducationListv2: (userId: userId) =>
    client.get(`/v2/candidate/${userId}/education`)
    .then(({ data }) => data),

 getInstitutionsv2: (search: getInstitutionsType) => client
    .get(`/v2/institutions?per_page=10${(typeof search === 'string' ) ?  `&search=${search}` : '' }`)
   .then(({ data }) => data),

 getCoursesv2: (search: getCoursesType) => client
   .get(`/v2/courses?per_page=10${(typeof search === 'string' ) ?  `&search=${search}` : '' }`)
   .then(({ data }) => data),

   createUserEducationv2: ({ payload, userId } : createEducationType) =>
    client
      .post(`/v2/candidate/${userId}/education`, { ...payload })
      .then(({ data }) => data),
  
  updateUserEducationv2: ({id, payload, userId}: updateEducationType) =>
    client
      .put(`/v2/candidate/${userId}/education/${id}`, { id, ...payload })
      .then(({ data }) => data),

  deleteUserEducationv2: ({id, userId}: deleteEducationType) =>
    client
      .delete(`/v2/candidate/${userId}/education/${id}`)
      .then(({ data }) => data),

  createUserExperiencev2: ({payload, userId}: createUserExperinceType) =>
    client
      .post(`/v2/candidate/${userId}/experience`, { ...payload })
      .then(({ data }) => data),

  updateUserExperiencev2: ({id, payload, userId}: updateUserExperinceType) =>
    client
      .put(`/v2/candidate/${userId}/experience/${id}`, { id, ...payload })
      .then(({ data }) => data),

  getUserExperienceListv2: ({userID}: userId) =>
    client.get(`/v2/candidate/${userID}/experience`).then(({ data }) => data),

  deleteUserExperience: ( { id, userId } : deleteUserExperinceType ) =>
    client
      .delete(`/v2/candidate/${userId}/experience/${id}`)
      .then(({ data }) => data),

  getNyscv2: ({userID}: userId) =>
    client.get(`/v2/candidate/${userID}/nysc-data`).then(({ data }) => data),

  AddNyscData: ({ payload, userId } : addNyscDataType) =>
    client
      .post(`/v2/candidate/${userId}/nysc-data`, { ...payload })
      .then(({ data }) => data),
}
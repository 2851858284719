import React, { useState } from "react";
import UpdatedNavbar from "../../layout/UpdateNavbar";
import LoginDrpdown from "./components/LoginDrpdown";
import Footer from "../../layout/Footer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { api } from "../../api/index.js";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { hashSync } from "bcryptjs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {

  function greetTime() {
    const today = new Date();
    const hourOfDay = today.getHours(); //The current hour of today
    if (hourOfDay > 11 && hourOfDay <= 16) {
      return "afternoon";
    } else if (hourOfDay > 16) {
      return "evening";
    } else {
      return "morning";
    }
  }
  const [searchParams]= useSearchParams()
  const redirect = searchParams.get('redirect')
  const campaignId = searchParams.get('campaign-id')
  const campaign = searchParams.get('application')
  const { register, handleSubmit } = useForm();
  const [accountType, setAccounType] = React.useState("candidate");
  const initialValues = { email: "", password: "" };
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const hasExamMutation = useMutation(api.getAssesmentsv2,{
     onSuccess: response => {
        if(sessionStorage.getItem('redirected')){
           sessionStorage.removeItem('redirected')
        }
        sessionStorage.setItem('has-exam', response?.data?.upcoming_exams_count)
        if(response?.data?.upcoming_exams_count){
           document.querySelector('#nofication-modal').style.display = 'block'
        }
     }
  } )

  const loginMutaion = useMutation(api.login, {
    onMutate: () => {
      toast.loading("Logging in...");
    },
    onSuccess: (data) => {
      toast.remove();
      toast.success("Logged in successfully...");
      sessionStorage.setItem("userToken", data.data.token);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
      sessionStorage.setItem("account-type", hashSync(accountType, 10));
      sessionStorage.setItem("is-authenticated", "1");
      setTimeout( () => {
        hasExamMutation.mutate(data?.data?.user?.user_id)
      } , 500 )
      if(campaignId){
        navigate(`/job-listings/submit-application/${campaignId}?application=${campaign}`)
      } 
      else navigate(-1);
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.response?.data.message);
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "email address is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    } else if (!values.password) {
      errors.password = "Password cannot be empty.";
    } else if (values.password.toString().length < 8) {
      errors.password = "Password cannot be less than 8 characters.";
    }
    return errors;
  };

  const onSubmit = (values, { setSubmitting }) => {
    loginMutaion.mutate({
      account_type: accountType,
      email: values.email,
      password: values.password,
      email_verification_required: false,
    });
    setSubmitting(false);
  };

  return (
    <div className="force-white-mode">
      <UpdatedNavbar />
      <div className="flex justify-center items-center   mt-10 2xl:mt-0  2xl:h-screen">
        <div className="bg-cover bg-no-repeat  bg-hero-img w-full md:w-[600px] lg:w-[900px] 2xl:w-[1440px] 2xl:h-[800px]  rounded-xl p-2 lg:p-10 ">
          <div className="my-2 shadow-xl rounded-[8px] bg-white w-full lg:w-1/2 2xl:h-[600px] 2xl:mt-28 p-4 2xl:p-6">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center lg:px-4 pt-8 my-auto md:justify-start md:pt-0  2xl:px-10">
                <div className="2xl:mb-10">
                  <div className="flex md:items-center justify-start w-full  overflow-hidden">
                    <h1 className="text-3xl md:w-3/4 object-contain font-medium text-darkBlue">
                      Good {greetTime()}
                    </h1>
                  </div>
                  <p className="my-2 text-smFont text-slate-500">
                    Please sign into your account
                  </p>
                </div>
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <label
                        htmlFor="email"
                        className="text-sm mb-[2px] ml-[3px]"
                      >
                        Email Address <span className="text-red-500">*</span>
                      </label>
                      <div className="flex relative mb-1 2xl:mb-5">
                        <span className=" inline-flex rounded-l-lg  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                          <svg
                            width="15"
                            height="15"
                            fill="currentColor"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                          </svg>
                        </span>
                        <Field
                          type="email"
                          id="email"
                          placeholder="email.."
                          name="email"
                          className=" flex-1 appearance-none rounded-r-lg border border-gray-300 w-full py-3 2xl:py-8 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-[#88a848]  focus:border-transparent"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        className="text-sm text-red-500"
                        component="div"
                      />
                      <label
                        htmlFor="password"
                        className="text-sm mb-[2px] ml-[3px] mt-2"
                      >
                        Password <span className="text-red-500">*</span>
                      </label>
                      <div className="flex relative">
                        <span className=" inline-flex rounded-l-lg items-center px-3 border bg-white   border-gray-300 text-gray-500 shadow-sm text-sm">
                          <svg
                            width="15"
                            height="15"
                            fill="currentColor"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                          </svg>
                        </span>
                        <div className="relative flex  w-full focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent">
                          <Field
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="password.."
                            className=" flex-1 appearance-none  border-y border-gray-300 w-full py-3 px-4 2xl:py-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none "
                          />
                          <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="inline-flex rounded-r-lg  appearance-none  items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm cursor-pointer focus:outline-none "
                          >
                            {showPassword ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </button>
                        </div>
                      </div>
                      <ErrorMessage
                        name="password"
                        className="text-sm text-red-500"
                        component="div"
                      />
                      <LoginDrpdown
                        isSubmitting={isSubmitting}
                        setAccounType={setAccounType}
                        accountType={accountType}
                      />
                    </Form>
                  )}
                </Formik>
                {accountType === "candidate" && (
                  <>
                    <div className="pt-1 flex justify-start">
                      <p className="text-sm">
                        <Link
                          to={`/forgot-password`}
                          className="font-semibold underline"
                        >
                          Forgot Password?
                        </Link>
                      </p>
                    </div>
                    <div className="pt-8  text-center">
                      <p className="text-sm">
                        Dont have an account?&nbsp;
                        <Link
                          to={ campaignId ? `/register?campaign-id=${campaignId}&application=${campaign}` : '/register'}
                          className="font-semibold underline"
                        >
                          Register
                        </Link>
                      </p>
                    </div>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <Footer />
      </div>
    </div>
  );
};

export default Login;

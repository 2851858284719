 import { createApiClient } from "./apiClient";

const client = createApiClient()

export const api = {
  /* *********************************************************************************************** */
  //LOGIN
  login: (payload) =>
    client.post("/v2/auth/login/candidate", { ...payload }).then(({ data }) => data),
  /* *********************************************************************************************** */
  //LOGout
  logout: () => client.post("/v2/auth/logout").then(({ data }) => data),
  /* *********************************************************************************************** */
  // get job listings
  getJobListings: (payload) =>
    client
      .get(
        `/v2/listings?per_page=${payload.per_page}&page=${payload.currentPage}${
          payload?.search ? "&search=" + payload?.search : ""
        }${
           payload?.sort ? "&sort=" + payload?.sort : ""
         }${
           payload?.location_id ? "&location_id=" + payload?.location_id : ""
         }${
          payload?.function_id ? "&function_id=" + payload?.function_id : ""
         }${
          payload?.industry_id ? "&industry_id=" + payload?.industry_id : ""
         }`
      )
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  // get single job details
  getSingleJobListing: ({id, link}) =>
    client.get(`/v2/listings/${id}?link=${link}`).then(({ data }) => data),
  /* *********************************************************************************************** */
  // apply for job
  ApplyForJob: (payload) =>
    client
      .post(
        `/v2/listings/${payload.id}`,
        payload?.affiliateSlug
          ? {
              affiliate_link_slug: payload.affiliateSlug,
              roles_selected: payload?.roles_selected,
            }
          : {
              roles_selected: payload?.roles_selected,
            }
      )
      .then(({ data }) => data),

  resetPassword: (payload) =>
    client
      .post(`/v2/auth/reset-password`, { ...payload })
      .then(({ data }) => data),

  forgotPassword: (payload) =>
    client
      .post(`/v2/auth/forgot-password`, {
        ...payload,
      })
      .then(({ data }) => data),

  verifyCampaignData: (payload) =>
    client
      .post(`/v2/applications/verify-data`, { ...payload })
      .then(({ data }) => data),

  // SIGN UP OR REGISTER CANDIDATE
  signUp: (payload) =>
    client
      .post(`/v2/auth/registration/candidate`, { ...payload })
      .then(({ data }) => data),

  /* *********************************************************************************************** */
  // apply for job
  saveJobInABookmark: (slug) =>
    client.post(`/v2/listings/${slug}/bookmarks`).then(({ data }) => data),
  /* *********************************************************************************************** */
  // get bookmarks
  getBookmarks: (pageNo) =>
    client
      .get(`/v2/listings/bookmarks${pageNo ? "?page=" + pageNo : ""}`)
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  // billing plans
  getPlans: () => client.get(`/v2/plans`).then(({ data }) => data),
  /* *********************************************************************************************** */
  //  topup account
  topUpAccount: (payload) =>
    client
      .post(`/v1/admin/billings/top-up`, { ...payload })
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  // newsletter email
  newsLetterSubscribe: (payload) =>
    client
      .post(`/v2/newsletter/subscribe`, { ...payload })
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  submitAffiliate: (data) =>
    client
      .post(`/v2/listings/${data.campaignId}/affiliates/${data.affiliateSlug}`)
      .then(({ data }) => data),
  /* *********************************************************************************************** */

  AddNyscData: (payload) =>
    client
      .post(`/v2/candidate/profile/nysc-data`, { ...payload })
      .then(({ data }) => data),

  getUserExperienceList: () =>
    client.get(`/v2/candidate/profile/experience`).then(({ data }) => data),

  getNysc: () =>
    client.get(`/v2/candidate/profile/nysc-data`).then(({ data }) => data),

  getProfileStatus: () =>
    client.get(`/v2/candidate/profile/status`).then(({ data }) => data),

  createUserExperience: (payload) =>
    client
      .post(`/v2/candidate/profile/experience`, { ...payload })
      .then(({ data }) => data),

  updateUserExperience: (id, payload) =>
    client
      .put(`/v2/candidate/profile/experience/${id}`, { id, ...payload })
      .then(({ data }) => data),

  getInstitutions: (search) => client
   .get(`/v2/institutions?per_page=10${(typeof search === 'string' ) ?  `&search=${search}` : '' }`)
  .then(({ data }) => data),

  getUserEducationList: () =>
    client.get(`/v2/candidate/profile/education`).then(({ data }) => data),

  getCourses: (search) => client
  .get(`/v2/courses?per_page=10${(typeof search === 'string' ) ?  `&search=${search}` : '' }`)
  .then(({ data }) => data),

  getStates: () => client.get(`/v2/states`).then(({ data }) => data),
  getDegrees: () => client.get(`/v2/degrees`).then(({ data }) => data),

  getClassOfDegrees: () =>
    client.get(`/v2/class-of-degrees`).then(({ data }) => data),

  verifyEmail: (payload) =>
    client.post("/v2/auth/email-verification", payload).then(({ data }) => data),

  resendOtp: (payload) =>
    client
      .post("/v2/auth/resend-verification-otp", payload)
      .then(({ data }) => data),

  getUserBiodata: () =>
    client.get(`/v2/candidate/profile/bio-data`).then(({ data }) => data),

  updateUserBiodata: (payload) =>
    client
      .patch(`/v2/candidate/profile/bio-data`, { ...payload })
      .then(({ data }) => data),

  createUserEducation: (payload) =>
    client
      .post(`/v2/candidate/profile/education`, { ...payload })
      .then(({ data }) => data),

  updateUserEducation: (id, payload) =>
    client
      .put(`/v2/candidate/profile/education/${id}`, { id, ...payload })
      .then(({ data }) => data),

  deleteUserEducation: (id) =>
    client
      .delete(`/v2/candidate/profile/education/${id}`)
      .then(({ data }) => data),

  deleteUserExperience: (id) =>
    client
      .delete(`/v2/candidate/profile/experience/${id}`)
      .then(({ data }) => data),

    getFeedbackQuestions: (payload) => client.get(`/v2/feedbacks`, { ...payload })
    .then(({ data }) => data),

    sendFeedback: (payload) => client.post(`/v2/feedbacks`, { ...payload })
    .then(({ data }) => data),
    
    getFunctions: () => client.get(`/v2/functions`).then(({ data }) => data ),
    getLocations: () => client.get(`/v2/locations`).then(({ data }) => data ),
    getIndustries: () => client.get(`/v2/industries`).then(({ data }) => data ),

    addInstitution: (payload) => client.post(`/v2/candidate/institutions`,payload)
    .then(({ data }) => data),
    
    trackUpdate: (payload)  => client.patch(`/v2/listings/update-tracker`, payload),
    
    setVisiorFingerprint: (payload)  => client.post(`/v2/listings`, payload),

    getAssesmentsv2: (id) =>
      client.get(`/v2/candidate/${id}/exams/summary`)
     .then(({ data }) => data),
};

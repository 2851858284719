import { createApiClient } from "./apiClient";
import { getSingleJobType } from "./types/listings.types";

const client = createApiClient()

export const listings = {
       getJobListings: (payload: any) =>
        client
          .get(
            `/v2/listings?per_page=${payload.per_page}&page=${payload.currentPage}${
              payload?.search ? "&search=" + payload?.search : ""
            }${
               payload?.sort ? "&sort=" + payload?.sort : ""
             }${
               payload?.location_id ? "&location_id=" + payload?.location_id : ""
             }${
              payload?.function_id ? "&function_id=" + payload?.function_id : ""
             }${
              payload?.industry_id ? "&industry_id=" + payload?.industry_id : ""
             }`
          )
          .then(({ data }) => data),

        getFunctionsv2: () => client.get(`/v2/functions`).then(({ data }) => data ),
        getLocationsv2: () => client.get(`/v2/locations`).then(({ data }) => data ),
        getIndustriesv2: () => client.get(`/v2/industries`).then(({ data }) => data ),
       
        getSingleJobListingv2: ({id,  link}: getSingleJobType) =>
            client.get(`/v2/listings/${id}?link=${link}`).then(({ data }) => data),

        ApplyForJobv2: (payload: any) =>
            client
              .post(
                `/v2/listings/${payload.id}`,
                payload?.affiliate_id
                  ? { ...payload,
                      affiliate_link_slug: payload.affiliate_id,
                      //roles_selected: payload?.roles_selected,
                    }
                  : {...payload}
              )
              .then(({ data }) => data),
}
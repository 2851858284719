import React, { useState } from "react";
import BiodataModal from "./EditBiodata";
import moment from "moment";

function BioData({ refetchStatus, biodata, isLoading, isError, refetch, error }) {

  const [openEditBioModal, setOpenEditBioModal] = useState(false);
  const [addBioModal, setAddBioModal] = useState(false);
  const [biodatas, setBioData] = useState(biodata?.data);

  return (
    <div className="mb-3">
      <div className="flex md:justify-between gap-x-6 items-center mb-2">
        <div className="font-semibold text-md text:darkBlue text-">Biodata</div>
        <button
          onClick={() => {
            setBioData(biodata);
            setOpenEditBioModal(true);
          }}
          className="cursor-pointer bi bi-pencil-square border border-armyGreen p-2 rounded-lg text-sm text-armyGreen"
        >
          &nbsp; Update biodata
          {/* <img src={EditIcon} alt="" /> */}
        </button>
        {/* <div onClick={() => setAddBioModal(true)} className="cursor-pointer">
          <img src={AddIcon} alt="" />
        </div> */}
        {openEditBioModal && (
          <BiodataModal
            closeModal={setOpenEditBioModal}
            biodata={biodatas?.data}
            refetch={refetch}
            refetchStatus={refetchStatus}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        )}
        {/* {addBioModal && <AddBiodata closeModal={setAddBioModal} />} */}
      </div>
      <div className="">
 
        <div className=" py-2 w-full">
          {/* <p className="font-bold text-sm text-darkBlue mb-2">#00001</p> */}
          <div className="flex w-96 gap-x-10 mb-3">
            <div>
              <h2 className="text-sm md:text-md text-gray-950 text-dark-blue">
                Full Name: {biodata?.data.name}
              </h2>
            </div>
          </div>
          <div className="w-full md:flex md:justify-between">
            {/* <div className="mb-2">
              <span className="text-xs text-lightGrey font-medium">
                Status:
              </span>{" "}
              <span className="text-sm text-[#87AA4B] font-bold">Active</span>
            </div> */}
            <div className="w-full md:w-1/2">
              <div className="mb-3">
                <span className="text-sm text-lightGrey font-normal">
                  Email:
                </span>{" "}
                <span className="text-sm font-medium text-darkBlue">
                  {biodata?.data.email}
                </span>
              </div>
              <div className="mb-3">
                <span className="text-sm text-lightGrey font-normal">
                  Phone:{" "}
                </span>{" "}
                <span className="text-sm font-medium text-darkBlue">
                  {biodata?.data.phone_number}
                </span>
              </div>

              <div className="mb-3">
                <span className="text-sm text-lightGrey font-normal">
                  Date of Birth:
                </span>{" "}
                <span className="text-sm font-medium text-darkBlue">
                  {biodata?.data.date_of_birth
                    ? moment(biodata?.data.date_of_birth).format("DD-MM-YYYY")
                    : null}
                </span>
              </div>
            </div>

            <div className="w-full md:w-1/2">
              <div className="mb-3">
                <span className="text-sm text-lightGrey font-normal">
                  State of origin:
                </span>{" "}
                <span className="text-sm font-medium text-darkBlue">
                  {biodata?.data.state_of_origin}
                </span>
              </div>
              <div className="mb-3">
                <span className="text-sm text-lightGrey font-normal">
                  State of residence:
                </span>{" "}
                <span className="text-sm font-medium text-darkBlue">
                  {biodata?.data.state_of_residence}
                </span>
              </div>
              <div className="mb-3">
                <span className="text-sm text-lightGrey font-normal">
                  {" "}
                  Gender:
                </span>
                <span className="text-sm font-medium text-darkBlue">
                  {biodata?.data.gender}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="bg-[#87AA4B] p-4 rounded-lg text-white max-w-[11rem] text-sm">
              Performance Report
            </div> */}
        </div>
      </div>
      <hr className="py-[0.5px]" />
    </div>
  );
}

export default BioData;

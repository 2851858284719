export const formatDate = (date_: string) : string => {
    const date = new Date(date_);
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
    const day = date.getDate();
    // Format in yyyy-m-d
    const formattedDate = `${year}-${ month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
    return (formattedDate);
  }
  
 export const reverseDate = (date: string) : Date => {
    const [day, month, year] = date.split('/'); // Split the string into day, month, year
    // Create a new Date object using the extracted parts (month is 0-indexed, so subtract 1)
    const formattedDate = new Date(`${year}-${month}-${day}`);
    return  new Date(formattedDate)
  }

  export const convertTo12Hour = (time24: string) : string => {
    // Split the time into hours, minutes, and seconds
    let [hours, minutes, seconds] = time24.split(":").map(Number);
    // Determine AM or PM
    let period =  Number(hours) > 11 ? "PM" : "AM";
    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert '0' or '12' to '12' for 12-hour format
    // Return the formatted time in 12-hour format
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")} ${period}`;
  }
  
  
import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../api";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import { ScaleLoader } from "react-spinners";
import CustomDropdown from "./../../../components/CustomDrpdown";
import { profile } from "../../../api/profile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from '../../../utils/dateFormatter';

const useDebounce = (fn, delay) => {
  let timeout;
  // return function that takes arg and apply to the function
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

function AddEducation({ closeModal, refetchEducation, refetchStatus }) {

  const customToast = useCustomToast();
  const [institutionSearch, setInstitutionSearch] = useState("");
  const [searchInstitutionLoading, setSearchInstitutionLoading] = useState(false)
  const [searchCourseLoading, setSearchCourseLoading] = useState(false)
  const [institutions, setInstitutions] = useState([]);
  const [isOthers, setIsOthers] = useState(false);
  const [otherInstitution, setOtherInstitution] = useState("");
  //const [degrees, setDegrees] = useState([])
  const [courses, setCourses] = useState([]);
  const { isLoading: createEducationLoading, mutateAsync} = useMutation(profile.createUserEducationv2)
  // fetch data
  const { isLoading } = useQuery("get-courses", profile.getCoursesv2, {
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setCourses(
        data?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
    },
  });

  const getCoursesMutation = useMutation(profile.getCoursesv2, {
    onSuccess: (response) => {
      setCourses(
        response?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
      setTimeout( () => setSearchCourseLoading(false) , 100 )
    },
  });

  const { data: degrees, degreesLoading } = useQuery(
    "get-degree",
    profile.getDegreesv2,
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (response) => response?.data,
    }
  );

  const { data: classOfDegrees, classOfDegreeLoading } = useQuery(
    "get-classOfDegrees",
    profile.getClassOfDegreesv2,
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (response) => response?.data,
    }
  );

  const { isLoading: institutionLoading, refetch } = useQuery(
    ["get-institution"],
    () => {
      return profile.getInstitutionsv2(institutionSearch);
    },
    {
      onSuccess: (response) => {
        setInstitutions(
          response?.data?.map((ins) => {
            return { label: ins?.name, value: ins?.id };
          })
        );
      },
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  // console.log(institutionLoading, institutionSearch);

  const getInstitutionsMutation = useMutation(profile.getInstitutionsv2, {
    onSuccess: (response) => {
      setInstitutions(
        response?.data?.map((ins) => {
          return { label: ins?.name, value: ins?.id };
        })
      );
      setTimeout( () => setSearchInstitutionLoading(false) , 100 )
    },
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  // console.log(getInstitutionsMutation.isLoading);
  // console.log(institutions);

  const addInstitutionMutation = useMutation(api.addInstitution, {
    onMutate: () => {
      toast.loading("Loading...");
    },
    onError: (error) => {
      toast.remove();
      toast.error(error?.response?.message);
    },
    onSuccess: (data) => {
      toast.remove();
      toast.success("Institution Added Successfully!");
      setOtherInstitution("");
      setIsOthers(false);
      refetch();
      setSelectedInstitutionId(data?.data?.id || "");
      setSelectedInstitution(data?.data?.name || "");
      setInstitutionValid(true);
    },
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // handle seletced states
  const [selectInstitution, setSelectedInstitution] = useState("");
  const [selectCourse, setSelectedCourse] = useState("");
  const [selectDegree, setSelectedDegree] = useState("");
  // const [selectState, setSelectedState] = useState("");
  const [selectClassOfDegree, setSelectedClassOfDegree] = useState("");
  const [degreeId, setDegreeId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [selectInstitutionId, setSelectedInstitutionId] = useState(null);
  const [classOfDegreeId, setClassOfDegreeId] = useState(null);

  // for validation
  const [institutionValid, setInstitutionValid] = useState(true);
  const [courseValid, setCourseValid] = useState(true);
  const [degreeValid, setDegreeValid] = useState(true);
  const [classofDegreeValid, setClassOfDegreeValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);

  const createEducation = async (e) => {
    e.preventDefault();

    setInstitutionValid(selectInstitution !== "");
    setCourseValid(selectCourse !== "");
    setDegreeValid(selectDegree !== "");
    setClassOfDegreeValid(selectClassOfDegree !== "");
    setStartDateValid(startDate !== "");
    setEndDateValid(endDate !== "");

    if (
      !institutionValid ||
      !courseValid ||
      !degreeValid ||
      !classofDegreeValid ||
      !startDate ||
      !endDate
    ) {
      toast.error("All fields are required");
      return;
    } else if (isOthers && !selectInstitutionId) {
      toast.error("Error! please select institution");
    } else {
      let formData = {
        name: selectInstitution,
        degree: selectDegree,
        degree_id: degreeId,
        course: selectCourse,
        course_id: courseId,
        class_of_degree: selectClassOfDegree,
        class_of_degree_id: classOfDegreeId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        institution: selectInstitution,
        institution_id: selectInstitutionId,
      };

      try {
        toast.loading("loading...");
        const response = await mutateAsync({
          payload: formData, userId: JSON.parse(sessionStorage.getItem('user'))?.user?.user_id
        });
        toast.remove();
        customToast.success(response?.message);
        sessionStorage.setItem("progress", "education");
        await refetchEducation();
        await refetchStatus();
        closeModal(false);
      } catch ({ response }) {
        toast.remove();
        customToast.error(response?.data?.message);
      }
    }
  };

  const addInstitution = () => {
    if (!otherInstitution?.trim())
      toast.error("Error! please add your institution name");
    else
      addInstitutionMutation.mutate({
        category: "other",
        name: otherInstitution,
      });
  };

  const debouncedSearch = useDebounce((input) => {
    getInstitutionsMutation.mutate(input);
    setInstitutionSearch(input);
  }, 1500);

  const handleSearch = React.useCallback(
    (value) => {
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const courseDebouncedSearch = useDebounce((input) => {
    getCoursesMutation.mutate(input);
    setInstitutionSearch(input);
  }, 1500);

  const handleCourseSearch = React.useCallback(
    (value) => {
      courseDebouncedSearch(value);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    if (getInstitutionsMutation.isLoading) {
       setSearchInstitutionLoading(true)
    } else {
      setSearchInstitutionLoading(false)
    }
  }, [getInstitutionsMutation.isLoading]);

  useEffect(() => {
    if (getCoursesMutation.isLoading) {
       setSearchCourseLoading(true)
    } else {
      setSearchCourseLoading(false)
    }
  }, [getCoursesMutation.isLoading]);

  return (
    <div className="fixed z-[10] top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50">
      {/* {(getInstitutionsMutation.isLoading || getCoursesMutation.isLoading) && (
        <div className="fixed z-[1000000000000] bg-black bg-opacity-70 top-0 left-0 h-[100vh] w-[100vw]">
          <div className="flex justify-center items-center h-full w-full">
            <div className="flex justify-center">
              <ScaleLoader color="#87AA4B" className="" />
            </div>
          </div>
        </div>
      )} */}
      <div className="flex items-center justify-center h-full w-full">
        <div className="w-[96%] overflow-y-scroll z-[10] h-[500px] md:h-[500px] md:w-[600px] bg-white rounded-[15px] p-8 md:p-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Add Education
          </h3>
          {isLoading || degreesLoading || classOfDegreeLoading || institutionLoading ? (
            <div className="flex justify-center">
              <ScaleLoader color="#87AA4B" className="" />
            </div>
          ) : (
            <form onSubmit={createEducation}>
              <div className="mb-4  items-center">
                {isOthers ? (
                  <div>
                    <label
                      htmlFor="gender"
                      className="text-gray-500 mb-4 text-sm font-medium"
                    >
                      Add your Institution
                      <span className="text-red-500 required-dot">*</span>
                    </label>
                    <input
                      className={`rounded-lg  flex-1 appearance-none border ${"border-lightGrey"}  w-full py-3 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-xs focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                      type="text"
                      value={otherInstitution}
                      onChange={(e) => setOtherInstitution(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="relative">
                    {  searchInstitutionLoading &&                 
                    <div className="absolute text-xs z-[100000000000] bg-white w-full py-3 md:w-[360px] text-center top-[80px]">
                       <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                    </div>
                    }
                    <label
                      htmlFor="gender"
                      className="text-gray-500 mb-4 text-xs font-medium"
                    >
                      Name of Institution
                      <span className="text-red-500 required-dot">*</span>
                    </label>
                    <CustomDropdown
                      width={"w-full"}
                      options={institutions}
                      value={
                        selectInstitutionId
                          ? {
                              value: selectInstitutionId,
                              label: selectInstitution,
                            } // Provide both value and label
                          : null
                      }
                      onChange={(selectedOption) => {
                        setSelectedInstitutionId(selectedOption?.value || "");
                        setSelectedInstitution(selectedOption?.label || "");
                        setInstitutionValid(!!selectedOption);
                      }}
                      onSearch={(input) => {
                        handleSearch(input);
                        setSearchInstitutionLoading(true)
                      }}
                      placeholder="Select from default list or search your institution"
                    />
                    {!institutionValid && (
                      <p className="text-red-500 text-xs mt-1 text-right">
                        Please select an institution
                      </p>
                    )}
                  </div>
                )}
               {/* <div className="flex items-center ml-4 md:mt-4 mt-3 md:pt-4">
                  <input
                    type="checkbox"
                    checked={isOthers}
                    onClick={() => setIsOthers(!isOthers)}
                    className="text-primary mr-2 bg-primary h-[20px] w-[20px]"
                    id="others"
                  />
                  <label htmlFor="other" className="text-xs">
                    Others
                  </label>
                  {isOthers && (
                    <button
                      onClick={addInstitution}
                      type="button"
                      className="px-2 ml-2 bg-primary bi bi-plus-circle text-white text-xs py-2 rounded-lg"
                    >
                      &nbsp;Add university
                    </button>
                  )}
                </div>*/}
              </div>

              <div className="mb-4 md:flex gap-x-4 relative">
                {  searchCourseLoading &&                 
                    <div className="absolute text-xs z-[100000000000] bg-white w-full py-3 md:w-[360px] text-center top-[80px]">
                       <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                    </div>
                    }
                <div className="mb-4 md:mb-0 w-full md:w-2/3">
                  <label
                    htmlFor="gender"
                    className="text-gray-500 mb-4 text-sm font-medium"
                  >
                    Courses
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    width={"w-full md:w-[360px]"}
                    options={courses}
                    value={
                      courseId ? { value: courseId, label: selectCourse } : null
                    }
                    onChange={(selectedOption) => {
                      setCourseId(selectedOption?.value || "");
                      setSelectedCourse(selectedOption?.label || "");
                      setCourseValid(!!selectedOption);
                    }}
                    placeholder="Select Course"
                    onSearch={(input) => {
                      setSearchCourseLoading(true)
                      handleCourseSearch(input);
                    }}
                  />
                  {!courseValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a course
                    </p>
                  )}
                </div>
                <div className="mb-4 md:mb-0 w-full md:w-1/3">
                  <label
                    htmlFor="degree"
                    className="text-gray-500 mb-4 text-sm font-medium"
                  >
                    Degree
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    width={"w-full md:w-[180px]"}
                    options={
                      degrees?.length
                        ? degrees?.map((degree) => {
                            return { label: degree?.name, value: degree?.id };
                          })
                        : []
                    }
                    value={
                      degreeId
                        ? { value: degreeId, label: selectDegree } // Provide both value and label
                        : null
                    }
                    onChange={(selectedOption) => {
                      setDegreeId(selectedOption?.value || "");
                      setSelectedDegree(selectedOption?.label || "");
                      setDegreeValid(!!selectedOption);
                    }}
                    placeholder="Select Degree"
                  />
                  {!degreeValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a degree
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-4 md:flex  gap-x-4">
                <div className="w-full  mb-4 md:mb-0">
                  <label
                    htmlFor="class_of_degree"
                    className="text-gray-500 mb-4 text-sm font-medium"
                  >
                    Class Of Degree
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    width={"w-full"}
                    options={
                      classOfDegrees?.length
                        ? classOfDegrees?.map((degree) => {
                            return { label: degree?.name, value: degree?.id };
                          })
                        : []
                    }
                    value={
                      classOfDegreeId
                        ? { value: classOfDegreeId, label: selectClassOfDegree } // Provide both value and label
                        : null
                    }
                    onChange={(selectedOption) => {
                      setClassOfDegreeId(selectedOption?.value || "");
                      setSelectedClassOfDegree(selectedOption?.label || "");
                      setClassOfDegreeValid(!!selectedOption);
                    }}
                    placeholder="Select Class Of Degree"
                  />
                  {!classofDegreeValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select class of degree
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-8 md:flex gap-x-4">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="start_date"
                    className="text-gray-500 block mb-1 text-sm font-medium"
                  >
                    Start Date
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <DatePicker
                     selected={startDate}
                     placeholderText="start date (dd/mm/yyyy)"
                     dateFormat="dd/MM/yyyy"
                     className="border border-slate-300 block focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 w-full"
                     //minDate={new Date()}
                     onChange={(value) => {
                      setStartDate(value);
                      setStartDateValid(true);
                    }}
                  />
                  {!startDateValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select start date
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="end_date"
                    className="text-gray-500 mb-1 block text-sm font-medium"
                  >
                    End Date
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <DatePicker
                     selected={endDate}
                     placeholderText="end date (dd/mm/yyyy)"
                     dateFormat="dd/MM/yyyy"
                     className="border border-slate-300 block focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 w-full"
                     minDate={new Date()}
                    onChange={(e) => {
                      setEndDate(e);
                      setEndDateValid(true);
                    }}
                  />
                  {!endDateValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select end date
                    </p>
                  )}
                </div>
              </div>

              <div className="flex gap-x-10 justify-center">
                <button
                  type="button" disabled={createEducationLoading}
                  className="inline-flex justify-center rounded-md border border-primary  shadow-sm px-8 py-1 md:px-16 md:py-2 bg-white text-xs font-medium text-primary  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-1 md:px-16 md:py-2 bg-armyGreen text-xs font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                >
                   Save
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddEducation;

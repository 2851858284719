import React, { useState } from "react";
import { api } from "../../../api";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import { profile } from "../../../api/profile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from '../../../utils/dateFormatter';
import { useMutation } from "react-query";

function AddExperience({ closeModal, refetch, refetchStatus }) {
  
  const customToast = useCustomToast();
  const [values, setValues] = useState({
    name: "",
    role: "",
    description: "",
    start_date: "",
    end_date: "",
  });
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const user = JSON.parse(sessionStorage.getItem('user'))?.user
  const { isLoading, mutateAsync } = useMutation(profile.createUserExperiencev2) 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

    // Clear the validation error for the field when the user starts typing again
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleDateInputChange = (name, value) => {
    setValues({ ...values, [name]: value });

    // Clear the validation error for the field when the user starts typing again
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCurrentlyWorkingChange = (e) => {
    setIsCurrentlyWorking(e.target.checked);
  };

  const createExperience = async (e) => {
    e.preventDefault();
  
    const requiredFields = ["name", "role", "description", "start_date"];
    const newValidationErrors = {};
  
    requiredFields.forEach((field) => {
      if (!values[field]) {
        newValidationErrors[field] = "This field is required.";
      }
    });
  
    if (!values.start_date) {
      newValidationErrors.start_date = "This field is required.";
    } else if (new Date(values.start_date) > new Date()) {
      newValidationErrors.start_date = "Start date must be a past date.";
    }
  
    if (!isCurrentlyWorking && values.end_date) {
      if (!values.start_date || new Date(values.start_date) > new Date(values.end_date)) {
        newValidationErrors.start_date = "Start date cannot be after end date.";
        newValidationErrors.end_date = "End date cannot be before start date.";
      }
    }
  
    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      return;
    }
  
    try {
      toast.loading("loading...");
      const payload = {...values, start_date: formatDate(values.start_date), end_date: formatDate(values.end_date) }
      const res = await mutateAsync({ payload, userId: user?.user_id })
      toast.remove();
      customToast.success(res.message);
      sessionStorage.setItem("progress", "experience");
      closeModal(false);
      await refetch();
      await refetchStatus();
    } catch (error) {
      toast.remove()
      if (error.res) {
        customToast.error(error.res.data.message);
      } else {
        toast.remove()
        customToast.error(error.message);
      }
    }
  };

  return (
    <div className="fixed z-[10] top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50">
      <div className="flex items-center justify-center h-full w-full">
        <div className="w-[96%] overflow-y-scroll z-[10] md:h-[95vh] md:w-[600px] bg-white rounded-[15px] p-8 md:p-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Add experience
          </h3>
          <div className="flex justify-between mb-4">
            <div className="text-darkBlue text-xs font-medium">Experience</div>
          </div>
          <form onSubmit={createExperience}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="text-gray-500 mb-4 text-sm font-medium"
              >
                Company name
                <span className="text-red-500 required-dot">*</span>
              </label>
              <input
                value={values.name}
                onChange={handleInputChange}
                type="text"
                id="name"
                className={`rounded-lg flex-1 appearance-none border ${
                  validationErrors.name ? "border-red-500" : "border-lightGrey"
                }  w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                name="name"
                placeholder="Name of company"
              />
              {validationErrors.name && (
                <p className="text-red-500 text-xs mt-1 text-right">
                  {validationErrors.name}
                </p>
              )}
            </div>
            <div className="flex flex-col md:flex-row items-center gap-x-2 mb-4">
              <div className="w-full mb-4 md:mb-0">
                <div className=" flex flex-col">
                  <label
                    htmlFor="role"
                    className="text-gray-500 mb-2 text-sm font-medium"
                  >
                    Role
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    placeholder="Your role e.g Software Developer"
                    value={values.role}
                    onChange={handleInputChange}
                    className={`rounded-lg  flex-1 appearance-none border ${
                      validationErrors.role
                        ? "border-red-500"
                        : "border-lightGrey"
                    } py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                  />
                  {validationErrors.role && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      {validationErrors.role}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4 flex gap-x-4">
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <label
                  htmlFor="start_date"
                  className="text-gray-500 mb-1 block text-sm font-medium"
                >
                  Start Date
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <DatePicker
                     selected={values.start_date}
                     placeholderText="start date (dd/mm/yyyy)"
                     dateFormat="dd/MM/yyyy"
                     className="border pr-16 border-slate-300 block w-full focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 "
                     maxDate={values.end_date}
                     onChange={(value) => {
                      handleDateInputChange('start_date', value)
                    }}
                />
                {validationErrors.start_date && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    {validationErrors.start_date}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <label
                  htmlFor="end_date"
                  className="text-gray-500 mb-1 block text-sm font-medium"
                >
                  End Date
                </label>
                {!isCurrentlyWorking && (
                   <DatePicker
                   selected={values.end_date}
                   placeholderText="end date (dd/mm/yyyy)"
                   dateFormat="dd/MM/yyyy"
                   className="border pr-16 border-slate-300 block w-full focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 "
                   minDate={values.start_date}
                   onChange={(value) => {
                    handleDateInputChange('end_date', value)
                  }}
                />
                )}
                <div className="mt-3">
                  <label
                    htmlFor="current-checkbox"
                    className="flex items-center"
                  >
                    <input
                      type="checkbox"
                      id="current-checkbox"
                      className=""
                      checked={isCurrentlyWorking}
                      onChange={handleCurrentlyWorkingChange}
                    />
                    <span className="ml-4 text-sm">I am currently working here</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label
                className="text-gray-500 mb-2 text-sm font-medium"
                htmlFor="description"
              >
                 Details of what you did
                <span className="text-red-500 required-dot">*</span>
              </label>
              <textarea
                value={values.description}
                onChange={handleInputChange}
                className={`mt-4 flex-1 w-full px-4 py-2 text-sm text-gray-700 placeholder-gray-400 bg-customGrey border ${
                  validationErrors.description
                    ? "border-red-500"
                    : "border-lightGrey"
                } rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                id="description"
                name="description"
                placeholder="Details of what you did"
                rows="5"
                cols="40"
              ></textarea>
              {validationErrors.description && (
                <p className="text-red-500 text-xs mt-1 text-right">
                  {validationErrors.description}
                </p>
              )}
            </div>

            <div className="flex gap-x-10 justify-center">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-armyGreen  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-white text-sm md:text-lg font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>

              <button
                type="submit" disabled={isLoading}
                className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-armyGreen text-sm md:text-lg font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default AddExperience;
